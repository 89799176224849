import * as React from "react"
import { graphql } from "gatsby"
// import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
// import { linkResolver } from "../utils/link-resolver"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import SliceZone from "../components/slice-zone"
import { JsonDebugger } from "../components/json-debugger"
// import { JsonDebugger } from "../components/json-debugger"
import { Typography } from "antd"
import PageContent from "../components/page-content"
import { Helmet } from "react-helmet"
import { empty } from "@apollo/client"
const { Title } = Typography

const convertDangerousHtmlToChildren = (node) => {
  if (node.props && node.props.dangerouslySetInnerHTML) {
    return {
      ...node,
      props: {
        ...node.props,
        dangerouslySetInnerHTML: undefined,
        children: node.props.dangerouslySetInnerHTML.__html.replace(
          /&quot;/g,
          '\\"'
        ),
      },
    }
  }
  return node
}

const PrismicPage = ({ data }) => {
  if (!data) return null
  const doc = data.prismicPage

  return (
    <Layout>
      <Seo title={doc.data.document_display_name.text} />
      {doc.data.indexable === false && (
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}
      <PageContent>
        <Title>{doc.data.document_display_name.text}</Title>
        <SliceZone sliceZone={doc.data.body} />
        {doc.data.embed_fragment &&
          doc.data.embed_fragment.map((embed, index) => {
            if (embed.position === "Header") {
              return (
                <Helmet key={index}>
                  {/* {convertDangerousHtmlToChildren(embed.code)} */}
                  {/* {embed.code} */}
                  <script
                    charSet="utf-8"
                    type="text/javascript"
                    src="//js.hsforms.net/forms/shell.js"
                  ></script>
                </Helmet>
              )
            }
            if (embed.position === "Script") {
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: `<script
                type="text/javascript"
              >${embed.code}</script> `,
                  }}
                >
                  {/* <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: embed.code,
                    }}
                  ></script> */}
                </div>
              )
              // return convertDangerousHtmlToChildren(
              //   <script
              //     key={index}
              //     type="text/javascript"
              //     dangerouslySetInnerHTML={{
              //       __html: embed.code,
              //     }}
              //   ></script>
              // )
            }
            if (embed.position === "Src") {
              return (
                <Helmet key={index}>
                  <script type="text/javascript" src={embed.code}></script>
                </Helmet>
              )
            }
            return (
              <div
                key={index}
                dangerouslySetInnerHTML={{ __html: embed.code }}
              />
            )
            // <span key={index}>
          })}
        {/* <JsonDebugger json={doc} /> */}
      </PageContent>
    </Layout>
  )
}

export const prismicPageQuery = graphql`
  query ($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      data {
        document_display_name {
          text
        }
        embed_fragment {
          code
          position
        }
        indexable
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyText
          ...PageDataBodyQuote
          ...PageDataBodyFullWidthImage
          ...PageDataBodyImageGallery
          ...PageDataBodyImageHighlight
          ...PageDataBodyCardsCarousel
          ...PageDataBodyVideoHighlight
          ...PageDataBodyHtml
        }
      }
    }
  }
`

export default PrismicPage
// export default withPrismicPreview(PageTemplate, [
//   {
//     repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
//     linkResolver,
//   },
// ])
